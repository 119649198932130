import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Utils
import { SECTION_MARGIN } from '../../utils/constants'
// Types
import { PageContextProps, PaginationContextProps } from '../../types/pages'
import { BlogLandingProps, CategoryProps, PostPreviewWithCategoriesProps } from '../../types/blog'
// Component
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Layout, Seo } from '../../components/commons'
import { Box } from '../../atoms'
import BlogHero from '../../components/blog/BlogHero'
import CategoriesList from '../../components/blog/commons/CategoriesList'
import PaginatedPosts from '../../components/blog/commons/PaginatedPosts'
import { FeaturedPost } from '../../components/blog/commons'

interface BlogQuery {
  blogLanding: BlogLandingProps
  categoryList: {
    nodes: CategoryProps[]
  }
  postList: {
    nodes: PostPreviewWithCategoriesProps[]
  }
}

type PageContext = PageContextProps & PaginationContextProps

const Blog: React.FC<PageProps<BlogQuery, PageContext>> = ({ pageContext, data }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath } = pageContext
  const {
    blogLanding: {
      pageInfo,
      meta,
      heroHeadline,
      heroSubHeadline,
      heroForm,
      featuredPost,
      allPostsLabel,
      paginationPreviousLabel,
      paginationNextLabel,
    },
    categoryList: { nodes: categories },
    postList: { nodes: posts },
  } = data

  return (
    <Layout pageId={pageContext.id} locale={pageContext.locale} allSlugLocales={pageContext.slugLocales}>
      <Seo
        slug={pageInfo.slug}
        canonicalUrl={pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={meta}
        content={pageInfo.seo}
        preventIndexing={pageInfo.preventIndexing}
      />

      <BlogHero headline={heroHeadline} subHeadline={heroSubHeadline} form={heroForm} />

      <Box>
        <Grid>
          <Row>
            <Col xs={12}>
              {/* Featured Post */}
              {currentPage === 1 && featuredPost && (
                <Box mb={[8, 9, 80]}>
                  <FeaturedPost data={featuredPost} />
                </Box>
              )}

              {/* Categories */}
              <CategoriesList
                blogLandingPageInfo={pageInfo}
                allLabel={allPostsLabel}
                categories={categories}
                isAll={true}
              />

              {/* Posts */}
              <Box mt={[6, 7, 8]} mb={SECTION_MARGIN}>
                <PaginatedPosts
                  posts={posts}
                  prevLabel={paginationPreviousLabel}
                  nextLabel={paginationNextLabel}
                  previousPagePath={previousPagePath}
                  nextPagePath={nextPagePath}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              </Box>
            </Col>
          </Row>
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($skip: Int!, $limit: Int!, $locale: String!) {
    blogLanding: datoCmsBlog(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      heroHeadline
      heroSubHeadline
      heroForm {
        ...hubspotFormFragment
        ...typeformFormFragment
      }
      featuredPost {
        ...postPreviewWithCategoriesFragment
      }
      allPostsLabel
      paginationPreviousLabel
      paginationNextLabel
    }
    categoryList: allDatoCmsCategory(locale: $locale, sort: { fields: name }) {
      nodes {
        ...categoryFragment
      }
    }
    postList: allDatoCmsPost(
      locale: $locale
      limit: $limit
      skip: $skip
      filter: { _allContentLocales: { elemMatch: { locale: { eq: $locale } } } }
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      nodes {
        ...postPreviewWithCategoriesFragment
      }
    }
  }
`

export default Blog
